<template>
    <div class="zy-page">
        <!-- 分页 -->
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageModel.pagenum"
            :page-sizes="pageSizes"
            :page-size="pageModel.pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        ></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'zy-page',
    props: {
        // 分页查询数据
        pageModel: Object,
        // 总数
        total: Number,
        // 分页数
        pageSizes: {
            type: Array,
            default: function() {
                return [1, 2, 5, 10]
            }
        }
    },
    data() {
        return {}
    },
    methods: {
        handleSizeChange(ns) {
            this.$emit('size-change', ns)
            console.log('分页....ns...>>', ns)
        },
        handleCurrentChange(np) {
            this.$emit('current-change', np)
            console.log('分页....np.....>>', np)
        }
    }
}
</script>
