<script>
export default {
    name: 'zy_table',
    props: {
        // 表头
        tbHead: {
            type: Array
        },

        // 内容
        tbBody: {
            type: Array
        },

        // 斑马纹
        stripe: {
            type: Boolean,
            default: true
        },

        // 边框
        border: {
            type: Boolean,
            default: true
        },

        // 固定表头
        height: String,

        // 单选
        highlightCurrentRow: {
            type: Boolean,
            default: false
        },

        // 多选
        selection: {
            type: Boolean,
            default: false
        },

        // 默认排序
        defaultSort: Object,

        // 合计
        showSummary: {
            type: Boolean,
            default: false
        },

        // 合计名称
        sumText: {
            type: String,
            default: '合计'
        },

        // 自定义合计
        getSummaries: Function,

        // 表格类名
        className: String,

        // 显示表头
        showHeader: {
            type: Boolean,
            default: true
        },

        // 表头样式
        headerCellStyleProp: {
            type: Object,
            required: false
        },
        // 内容样式
        cellStyleProp: {
            type: Object,
            required: false
        }
    },
    created() {
        // 表头样式
        const hs = {
            background: 'rgb(64,158,255)',
            color: '#fff'
        }

        const hsKey = this.headerCellStyleProp
            ? Object.keys(this.headerCellStyleProp)
            : []
        console.log(hsKey.length > 0)
        hsKey.length > 0
            ? (this.headerCellStyle = this.headerCellStyleProp)
            : (this.headerCellStyle = hs)

        // 内容样式
        const cs = { padding: '4px 0' }
        const csKey = this.cellStyleProp ? Object.keys(this.cellStyleProp) : []
        csKey.length > 0
            ? (this.cellStyle = this.cellStyleProp)
            : (this.cellStyle = cs)
    },
    data() {
        return {
            // 表头样式
            headerCellStyle: {},
            // 内容样式
            cellStyle: {}
        }
    },
    render() {
        const {
            tbHead,
            tbBody,
            stripe,
            border,
            highlightCurrentRow,
            showSummary,
            sumText,
            showHeader,
            height,
            defaultSort,
            getSummaries,
            className,
            handleCurrentChange,
            handleSelectionChange,
            handleCellDblclick,
            headerCellStyle,
            cellStyle
        } = this
        const colNode = (item, index) => {
            if (item.slot) {
                return (
                    <el-table-column
                        align={item.align}
                        header-align={item.headerAlign}
                        label={item.label}
                        width={item.width}
                        key={index}
                    >
                        {this.$scopedSlots[item.slot]}
                    </el-table-column>
                )
            } else if (item.hide) {
                return
            } else {
                return (
                    <el-table-column
                        prop={item.prop}
                        label={item.label}
                        width={item.width}
                        show-overflow-tooltip={item.overflow}
                        sortable={item.sortable}
                        class-name={item.class}
                        min-width={item.miw}
                        align={item.align}
                        header-align={item.headerAlign}
                        key={index}
                    ></el-table-column>
                )
            }
        }

        const colNodeArr = []

        for (let i = 0; i < tbHead.length; i++) {
            colNodeArr.push(colNode(tbHead[i], i))
        }

        return (
            <div class="zy-table">
                <el-table
                    ref="zyTable"
                    class={className}
                    data={tbBody}
                    stripe={stripe}
                    border={border}
                    height={height}
                    highlight-current-row={highlightCurrentRow}
                    default-sort={defaultSort}
                    show-summary={showSummary}
                    sum-text={sumText}
                    summary-method={getSummaries}
                    show-header={showHeader}
                    on-current-change={handleCurrentChange}
                    on-selection-change={handleSelectionChange}
                    on-cell-dblclick={handleCellDblclick}
                    header-row-class-name="zy-table-header"
                    header-cell-style={headerCellStyle}
                    cell-style={cellStyle}
                >
                    {...this.$slots['column-before'] || ''}
                    {...colNodeArr}
                    {...this.$slots['column-after'] || ''}
                </el-table>
            </div>
        )
    },
    methods: {
        // 单选事件
        handleCurrentChange(currentRow, oldCurrentRow) {
            // 如果不需要单选 直接return
            if (!this.highlightCurrentRow) return

            // 向外暴漏currentRow,oldCurrentRow
            this.$emit('current-change', currentRow, oldCurrentRow)
        },
        // 多选事件
        handleSelectionChange(selection) {
            // 如果不需要多选 直接return
            if (!this.selection) return

            // 向外暴漏selection
            this.$emit('selection-change', selection)
        },
        handleCellDblclick() {}
    }
}
</script>
